'use client';

import { LinkBehaviour } from '@/components/link-behaviour';
import { i18nConfig } from '@/i18n/config';
import { ThemeOptions } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import defaultShadows, { Shadows } from '@mui/material/styles/shadows';

import {
  enUS as muiEn,
  esES as muiEs,
  frFR as muiFr,
  ptPT as muiPt,
  Localization as MuiLocalization
} from '@mui/material/locale';

import {
  enUS as gridEn,
  esES as gridEs,
  frFR as gridFr,
  ptPT as gridPt
} from '@mui/x-data-grid/locales';
import { Localization as GridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';
import { buttonClasses } from '@mui/material/Button';

const shadows = [...defaultShadows] as Shadows;
shadows[1] = '0 0 8px #0000000a';
shadows[2] =
  'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px';

const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#0077F7',
      contrastText: '#fff'
    },
    secondary: {
      main: '#FF770E',
      contrastText: '#fff',
      lighter: '#fff4ec',
      darker: '#882c14'
    },
    success: {
      main: '#40C85E',
      contrastText: '#fff'
    },
    error: {
      main: '#E63E46',
      contrastText: '#fff'
    },
    warning: {
      main: '#FAA700',
      contrastText: '#fff'
    }
  },
  shadows,
  shape: {
    borderRadius: 6
  },
  typography: {
    fontFamily: 'inherit',
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.primary.main
              : theme.palette.primary.dark
        }),
        colorSecondary: ({ theme }) => ({
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.secondary.main
              : theme.palette.secondary.dark
        })
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 24,
          [`&.${buttonClasses.outlined}.${buttonClasses.colorInherit}`]: {
            borderColor: theme.palette.action.focus
          }
        })
      },
      variants: [
        {
          props: { variant: 'filter' },
          style: ({ theme }) => ({
            height: '44px',
            borderRadius: '6px',
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
            color: theme.palette.text.primary,
            fontSize: '14px',
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              color:
                theme.palette.mode === 'dark'
                  ? '#FFF'
                  : theme.palette.text.primary
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#000',
              color: theme.palette.mode === 'dark' ? '#000' : '#FFF'
            }
          })
        },
        {
          props: { variant: 'neutral' },
          style: ({ theme }) => ({
            fontWeight: 600,
            backgroundColor:
              theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
            '&:hover': {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.grey[800]
                  : theme.palette.grey[300],
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.light
                  : theme.palette.secondary.main
            }
          })
        },
        {
          props: { variant: 'inverted' },
          style: ({ theme }) => ({
            fontWeight: 600,
            backgroundColor: '#FFF',
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.grey[300],
              color: theme.palette.primary.main
            }
          })
        }
      ]
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehaviour
      },
      styleOverrides: {
        root: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiCardActionArea: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehaviour
      },
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        scroll: 'body'
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: ({ theme }) => ({
          color: theme.palette.error.light
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      },
      styleOverrides: {
        outlined: {
          fontFamily: 'inherit',
          fontWeight: 500,
          position: 'static',
          transform: 'scale(0.75)'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
        fontWeight: 500,
        underline: 'hover',
        color: 'primary.dark'
      },
      styleOverrides: {
        root: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          legend: {
            maxWidth: 0,
            visibility: 'hidden',
            opacity: 0
          }
        }
      }
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave'
      }
    },
    MuiStack: {
      defaultProps: {
        direction: 'row',
        spacing: 1
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '1rem'
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiTreeItem2: {
      styleOverrides: {
        label: {
          fontFamily: 'inherit'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit'
        }
      }
    }
  }
};

const muiLocaleMap: Record<string, MuiLocalization> = {
  en: muiEn,
  es: muiEs,
  fr: muiFr,
  pt: muiPt
};

const gridLocaleMap: Record<string, GridLocalization> = {
  en: gridEn,
  es: gridEs,
  fr: gridFr,
  pt: gridPt
};

export function createLocalizedTheme(lang: string) {
  return extendTheme(
    {
      colorSchemes: {
        light: deepmerge(
          baseThemeOptions,
          {
            palette: {
              background: {
                default: '#F5F5F7'
              }
            }
          },
          { clone: true }
        ),
        dark: deepmerge(baseThemeOptions, {}, { clone: true })
      }
    },
    muiLocaleMap[lang],
    gridLocaleMap[lang]
  );
}

export const theme = createLocalizedTheme(i18nConfig.defaultLocale);
